import React from 'react'

const About4 = (props) => {

    return (
        <section className="about-page-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-8 col-md-10">
                        <div className="section-title-s3">
                            <span>#About us</span>
                            <h2>When  <span>hope is hungry</span> everything feeds it.</h2>
                            <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather or then turned to look out.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="about-features-grid">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-pencil-case"></i>
                                </div>
                                <h3>Education</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-fruits"></i>
                                </div>
                                <h3>Food</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-donation"></i>
                                </div>
                                <h3>Hope</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-charity"></i>
                                </div>
                                <h3>Prayer</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-smile"></i>
                                </div>
                                <h3>Children</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-like"></i>
                                </div>
                                <h3>Love</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About4;