import atimg1 from '../images/team/img-1.jpg'
import atimg2 from '../images/team/img-2.jpg'
import atimg3 from '../images/team/img-3.jpg'
import atimg4 from '../images/team/img-4.jpg'
import atimg5 from '../images/team/img-5.jpg'
import atimg6 from '../images/team/img-6.jpg'


const Team = [
   {
      Id: '1',
      AtImg: atimg1,
      name: 'Michel Troat',
      slug: 'Michel-Troat',
      title: 'Dedicated volunteer',
   },
   {
      Id: '2',
      AtImg: atimg2,
      name: 'Jonathon Danial',
      slug: 'Jonathon-Danial',
      title: 'Dedicated volunteer',
   },
   {
      Id: '3',
      AtImg: atimg3,
      name: 'Muli Overla',
      slug: 'Muli-Overla',
      title: 'Dedicated volunteer',
   },
   {
      Id: '4',
      AtImg: atimg4,
      name: 'Oncon Lucy',
      slug: 'Oncon-Lucy',
      title: 'Dedicated volunteer',
   },
   {
      Id: '5',
      AtImg: atimg5,
      name: 'Jane Cooper',
      slug: 'Jane-Cooper',
      title: 'Dedicated volunteer',
   },
   {
      Id: '6',
      AtImg: atimg6,
      name: 'Michel jhon',
      slug: 'Michel-jhon',
      title: 'Dedicated volunteer',
   }

]

export default Team;