import cimg1 from '../images/causes/img-1.jpg'
import cimg2 from '../images/causes/img-2.jpg'
import cimg3 from '../images/causes/img-3.jpg'
import eimg4 from '../images/causes/img-4.jpg'
import eimg5 from '../images/causes/img-5.jpg'

const Causes = [
    {
        id: '1',
        cImg:cimg1,
        process:'65',
        thumb:'Education',
        cTitle: 'Childrens voices and opinions the heart of our media',
        slug:'Childrens-voices', 
        Goal:'3,000.00',
        Raised:'2,000.00',
        authorName:'Laura Faiary',
    },
    {
        id: '2',
        cImg:cimg2,
        process:'75',
        thumb:'Food',
        cTitle: 'Ensure Clean Water and foods To The African Kids.', 
        slug:'African-Kids',
        Goal:'3,000.00',
        Raised:'2,000.00',
        authorName:'Laura Faiary',
    },
    {
        id: '3',
        cImg:cimg3,
        process:'85',
        thumb:'Medicine',
        cTitle: 'Your Help Can Save A Human Life From Death earts.', 
        slug:'Human-Life',
        Goal:'3,000.00',
        Raised:'2,000.00',
        authorName:'Laura Faiary',
    },
    {
        id: '4',
        cImg:eimg4,
        process:'95',
        thumb:'Elephant',
        cTitle: 'Give African Child A Good Education and foods', 
        slug:'Give-African-Child-A-Good-Education',
        Goal:'3,000.00',
        Raised:'2,000.00',
        authorName:'Laura Faiary',
    },
    {
        id: '5',
        cImg:eimg5,
        process:'55',
        thumb:'Tiger',
        cTitle: 'Raise Fund For Clean & Healthy Food and Good Education', 
        slug:'Raise-Fund-For-Clean',
        Goal:'3,000.00',
        Raised:'2,000.00',
        authorName:'Laura Faiary',
    },
    {
        id: '6',
        cImg:cimg3,
        process:'35',
        thumb:'WildLife',
        cTitle: 'Contribute Make For The Somalians happy and foods',
        slug:'Contribute-Make-For-The-Somalians-happy', 
        Goal:'3,000.00',
        Raised:'2,000.00',
        authorName:'Laura Faiary',
    },
]

export default Causes;